<template>
  <div v-if="askReview" class="cw-review text-center">
    <v-card class="py-2 py-md-4" outlined>
      <div class="my-2">
        <p class="text-center text-h6 font-weight-bold mb-0">
          <translate>How did we do?</translate>
        </p>
        <p class="text-center text-subtitle-2 mb-0">
          <translate>Please rate our service</translate>
        </p>
      </div>
      <v-card-text class="py-6">
        <div class="mb-4">
          <component :is="'logo'" class="mb-2"/>
        </div>
        <v-btn
          :dark="!reviewed"
          :disabled="reviewed"
          color="#3cbb91"
          @click="
            onSubmit();
            $eventLogger.clickEvent($event);
          "
        >
          <translate>Rate us</translate>
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'SaldoReview',

  components: {
    logo: () => import('@shared/assets/images/logos/reviewsio-logo.svg'),
  },

  inheritAttrs: false,

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    interval: 90,
    reviewed: false,
  }),

  computed: {
    ...mapGetters({
      country: 'application/country',
      isMobile: 'getMobile',
    }),

    askReview() {
      const lastReview = Number(localStorage.getItem('lastReview'));
      const askReviewAgain = new Date(lastReview);
      const today = new Date();

      askReviewAgain.setDate(askReviewAgain.getDate() + this.interval);

      return today > askReviewAgain;
    },

    branch() {
      const branches = {
        fi: 'finland',
        se: 'sweden',
        lt: 'lithuania',
      };

      return branches[this.country];
    },

    privacyPolicyLink() {
      const ppText = this.$gettext('Privacy Policy and Terms and Conditions');

      return `<a target='_blank' href='https://www.reviews.co.uk/front/user-privacy-policy'>${ppText}</a>`;
    },
  },

  mounted() {
    const reviewsLink = document.createElement('link');
    const reviewsScript = document.createElement('script');

    reviewsLink.setAttribute('rel', 'stylesheet');
    reviewsLink.setAttribute('href', 'https://widget.reviews.co.uk/write-review/dist.css');
    reviewsScript.setAttribute('src', 'https://widget.reviews.co.uk/write-review/dist.js');

    document.head.appendChild(reviewsLink);
    document.head.appendChild(reviewsScript);
  },

  methods: {
    onSubmit() {
      this.reviewed = true;
      localStorage.setItem('lastReview', Number(new Date()));

      DataLayer.push({ event: 'review-posted' });

      window.writeReviewWidget('widget', {
        store: 'saldo',
        customer_name: this.data.name,
        customer_email: this.data.email,
        order_id: this.data.id,
        delay: 1000,
        branch: this.branch,
        textPleaseWriteAReview: this.$gettext('Please Write a Review'),
        textWeDLoveToHearWhatYouThink: this.$gettext('We\'d love to hear what you think'),
        textRating: this.$gettext('Rating'),
        textComments: this.$gettext('Comments'),
        textMinimumCharacters: this.$gettext('Minimum 10 characters.'),
        textSubmit: this.$gettext('Submit'),
        textBoomYourReviewIsBeingProcessed: this.$gettext('Congratulations! Your review is being processed!'),
        textThankYouForHelpingOtherCustomersToMakeAnInformedDecision: this.$gettext('Thank you for helping other customers make an informed decision.'),
        textVeryPoor: this.$gettext('Very Poor'),
        textPoor: this.$gettext('Poor'),
        textAcceptable: this.$gettext('Acceptable'),
        textGood: this.$gettext('Good'),
        textVeryGood: this.$gettext('Very Good'),
        textTermsAndConditions: `${this.$gettext('Service provider')}: REVIEWS.io. ${this.privacyPolicyLink}.`,
      });
    },
  },
};
</script>

<style lang="scss">
.cw-review {
  svg {
    height: 30px;
  }
}

#wr_reviews_container .close {
  line-height: 1 !important;
}
</style>
